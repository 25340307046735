import { useContext, useState } from "react";
import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import {
  PhoneCountry,
  PhoneNumberInput,
  getCountries,
} from "components/common/composite";
import { useSendSmsConfirmationMutation } from "graphql/mutations";
import { useErrorLogger } from "hooks";
import { AuthContext } from "providers/Authentication";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

import { PopupWrapper } from "../PopupWrapper";
import { ValidateSMSCodeForm } from "./ValidateSMSCodeForm";

interface Props {
  onClose: () => void;
  onSuccessfulVerification: () => void;
}

export default ({ onClose, onSuccessfulVerification }: Props): JSX.Element => {
  const [hasSentCode, setHasSentCode] = useState(false);

  const { sendSmsConfirmation, isLoading } = useSendSmsConfirmationMutation();

  const { session } = useContext(AuthContext);
  const { t } = useTranslation("common");

  const validationSchema = Yup.object({
    country: Yup.mixed<PhoneCountry>().required(),
    number: Yup.string()
      .matches(/\d+/, {
        excludeEmptyString: true,
        message: t(
          "phoneNumberVerification.sendSMSCode.phoneNumber.error.format",
          "Incorrect phone format (only numbers)"
        ),
      })
      .required(
        t(
          "phoneNumberVerification.sendSMSCode.phoneNumber.error.required",
          "A phone number is required"
        )
      ),
  });
  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm<{
    country: PhoneCountry;
    number: string;
  }>({
    defaultValues: {
      country:
        getCountries().find(
          ({ countryCode }) => countryCode === session?.account.country
        ) ?? getCountries()[0],
      number: "",
    },
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const { reportErrors } = useErrorLogger();
  reportErrors(errors);

  const phone = watch();

  const formId = "send-sms-code-form";

  return (
    <PopupWrapper
      hasPrevious={false}
      onClose={onClose}
      title={t(
        "popup.phoneNumberVerification.sendSMSCode.heading",
        "Phone number verification"
      )}
      subTitle={
        hasSentCode
          ? t(
              "popup.phoneNumberVerification.validateSMSCode.paragraph",
              "We have sent you an SMS with a verification code. Type it below and we are ready to go!"
            )
          : t(
              "popup.phoneNumberVerification.sendSMSCode.paragraph",
              "For security reasons, we need to verify your identity. We will send an SMS with a verification code."
            )
      }
      variant="popup"
      footerActions={[
        {
          id: "validate_sms_code_form-submit_code",
          type: "submit",
          form: formId,
          variant: hasSentCode ? "secondary" : "primary",
          label: hasSentCode
            ? t(
                "popup.phoneNumberVerification.validateSMSCode.button.resendCode",
                "Resend the code"
              )
            : t(
                "popup.phoneNumberVerification.sendSMSCode.submit",
                "Send code"
              ),
          disabled: isLoading,
        },
      ]}
    >
      <div className={tw("flex", "flex-col", "space-y-4")}>
        <form
          id={formId}
          onSubmit={handleSubmit((values) => {
            const input = {
              countryCode: values.country.phonePrefix,
              number: values.number,
            };
            sendSmsConfirmation(input, () => setHasSentCode(true));
          })}
          className={tw("w-1/2")}
        >
          <PhoneNumberInput
            id="phone"
            label={t(
              "popup.phoneNumberVerification.sendSMSCode.phoneNumber.label",
              "Mobile nr."
            )}
            selectedCountry={phone.country}
            setSelectedCountry={(value) => setValue("country", value)}
            registerNumber={register("number")}
            disabled={hasSentCode}
            errorMessage={errors.number?.message}
          />
        </form>

        {hasSentCode && (
          <ValidateSMSCodeForm
            phoneNumber={{
              countryCode: phone.country.phonePrefix,
              number: phone.number,
            }}
            onSuccessfulValidation={onSuccessfulVerification}
          />
        )}
      </div>
    </PopupWrapper>
  );
};
