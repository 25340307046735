import { useEffect, useRef, useState } from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import { HiChevronDown, HiSearch } from "react-icons/hi";

import { Input } from "components/common/basic";
import { useDebounce, useMenu, usePagination } from "hooks";
import { useTranslation } from "translations";
import { getCountryNameFromCode } from "utils";
import { tw } from "utils/tw";

import { SingleSelectResults } from "../search/shared";
import getCountries, { type PhoneCountry } from "./getCountries";

interface Props
  extends Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    "type" | "value"
  > {
  id: string;
  label: string;
  selectedCountry: PhoneCountry;
  setSelectedCountry: (country: PhoneCountry) => void;
  registerNumber: UseFormRegisterReturn;
  hint?: string;
  helpText?: string;
  errorMessage?: string;
}

export default ({
  id,
  label,
  selectedCountry,
  setSelectedCountry,
  registerNumber,
  hint,
  helpText,
  errorMessage,
  children,
  ...props
}: Props): JSX.Element => {
  const [searchInput, setSearchInput] = useState("");
  const debouncedValue = useDebounce(searchInput, 750);

  const { page, pages, pageItems, goToPage, resetItems } = usePagination({
    items: [] as PhoneCountry[],
    perPage: 10_000,
  });

  const menuRef = useRef<HTMLDivElement>(null);
  const phoneNumberRef = useRef<HTMLInputElement | null>(null);
  const [isOpen, setIsOpen] = useMenu(menuRef);
  const toggleIsOpen = () => setIsOpen(!isOpen);

  const { t, i18n } = useTranslation("common");

  // Set filtered countries on search
  useEffect(() => {
    const [no, se, dk, fi, ...countries] = getCountries();
    const nordics = [no, se, dk, fi];
    const sortedCountries = countries.sort((a, b) => {
      const countryA = getCountryNameFromCode(a.countryCode, i18n.language);
      const countryB = getCountryNameFromCode(b.countryCode, i18n.language);

      return countryA < countryB ? -1 : 1;
    });
    const filteredCountries = [...nordics, ...sortedCountries].filter(
      ({ countryCode, phonePrefix }) => {
        const countryNameA = getCountryNameFromCode(
          countryCode,
          i18n.language
        ).toUpperCase();
        const countryNameB = getCountryNameFromCode(
          countryCode,
          countryCode
        ).toUpperCase();

        if (countryNameA.includes(searchInput.toUpperCase())) return true;
        if (countryNameB.includes(searchInput.toUpperCase())) return true;
        if (countryCode.includes(searchInput.toUpperCase())) return true;
        if (phonePrefix.includes(searchInput)) return true;

        return false;
      }
    );

    resetItems(filteredCountries);
  }, [debouncedValue]);

  const hasHelpText = helpText !== undefined;
  const hasError = errorMessage !== undefined;

  const helpTextId = hasHelpText ? `${id}-help` : undefined;
  const errorId = hasError ? `${id}-error` : undefined;
  const inputDescriptionIDs =
    hasHelpText || hasError ? `${helpTextId} ${errorId}` : undefined;

  const labelContainerStyles = tw("flex", "justify-between");

  const labelStyles = tw("block", "text-sm", "text-gray-700");

  const hintStyles = tw("text-sm", "text-gray-500");

  const inputBase = tw(
    "mt-1",
    "w-full",
    "flex",
    "shadow-sm",
    "rounded-md",
    "border",
    "border-gray-300",
    "focus-within:ring-1",
    "focus-within:ring-gray-900",
    "focus-within:border-gray-900"
  );
  const inputError = tw("border-2", "border-error", "bg-error-light");
  const inputDisabled = tw("bg-gray-100", "text-gray-700");
  const inputStyles = tw(inputBase, {
    [inputError as string]: hasError,
    [inputDisabled as string]: props.disabled,
  });

  const textBase = tw("mt-2", "text-sm");
  const helpTextStyles = tw(textBase, "text-gray-700");
  const errorMessageStyles = tw(textBase, "text-error");

  return (
    <div className={tw("relative")} ref={menuRef}>
      <div className={labelContainerStyles}>
        <label htmlFor={id} className={labelStyles}>
          {label}
        </label>

        {hint && <span className={hintStyles}>{hint}</span>}
      </div>

      <div className={inputStyles}>
        <button
          id={`${id}Country`}
          type="button"
          onClick={toggleIsOpen}
          className={tw(
            "pl-3",
            "pr-2",
            "flex",
            "items-center",
            "rounded-l-md",
            "hover:opacity-90",
            "text-gray-400",
            {
              "bg-error-light": hasError,
              [inputDisabled]: props.disabled,
            }
          )}
          aria-describedby={errorId}
          disabled={props.disabled}
        >
          {buttonLabel(selectedCountry)}
        </button>

        <label className={tw("flex-1", "flex", "items-center", "space-x-0.5")}>
          <span className={tw("sm:text-sm")}>
            {selectedCountry.phonePrefix}
          </span>

          <input
            {...props}
            {...registerNumber}
            ref={(instance) => {
              registerNumber.ref(instance);
              phoneNumberRef.current = instance;
            }}
            onPaste={(event) => {
              if (!phoneNumberRef.current) return;

              const value = event.clipboardData.getData("text");
              if (value.startsWith("+47") || value.startsWith("0047")) {
                event.preventDefault();

                // Set country
                const country = getCountries()[0];
                setSelectedCountry(country);

                // Set number
                const modifiedValue = value
                  .substring(value.indexOf("47") + 2)
                  .trim();
                registerNumber.onChange({ target: { value: modifiedValue } });
                phoneNumberRef.current.value = modifiedValue;
              }
            }}
            className={tw(
              "pl-0.5",
              "flex-1",
              "border-none",
              "focus:ring-transparent",
              "rounded-r-md",
              "sm:text-sm",
              {
                "bg-error-light": hasError,
                [inputDisabled]: props.disabled,
              }
            )}
            id={`${id}Number`}
            type="text"
            aria-invalid={hasError}
            aria-describedby={inputDescriptionIDs}
          />
        </label>
      </div>

      {helpTextId && (
        <p className={helpTextStyles} id={helpTextId}>
          {helpText}
        </p>
      )}

      {errorId && (
        <p className={errorMessageStyles} id={errorId}>
          {errorMessage}
        </p>
      )}

      {/* Prevent closing popups when clicking outside to save */}
      {isOpen && (
        <button
          className={tw(
            "fixed",
            "left-0",
            "top-0",
            "w-screen",
            "h-screen",
            "cursor-default"
          )}
          onClick={toggleIsOpen}
        />
      )}

      {isOpen && (
        <div
          className={tw(
            "absolute",
            "z-10",
            "w-full",
            "mt-1",
            "bg-silver",
            "shadow",
            "rounded-lg",
            "flex",
            "flex-col",
            "h-96"
          )}
        >
          <div className={tw("py-2", "px-4")}>
            <Input
              autoFocus
              id="countrySearch"
              name="countrySearch"
              defaultValue={searchInput}
              label={t(
                "phoneNumberInput.countrySearch.label",
                "Search for country"
              )}
              hideLabel
              onChange={(event) => setSearchInput(event.target.value)}
              LeadingIcon={HiSearch}
            />
          </div>

          <SingleSelectResults<PhoneCountry>
            itemKey="countryCode"
            items={pageItems}
            renderListItemLabel={renderListItemLabel(i18n.language)}
            onSelect={setSelectedCountry}
            onClose={() => setIsOpen(false)}
            page={page}
            pages={pages}
            setPage={goToPage}
            showPagination={false}
          />
        </div>
      )}
    </div>
  );
};

const renderListItemLabel = (language: string) => (country: PhoneCountry) => (
  <div className={tw("flex", "items-center", "justify-between")}>
    <div className={tw("flex", "items-center", "space-x-2")}>
      <span>{country.flag}</span>
      <p className={tw("block", "text-gray-900", "text-sm", "font-semibold")}>
        <span>
          {getCountryNameFromCode(country.countryCode, language)} (
          {getCountryNameFromCode(country.countryCode, country.languages[0])})
        </span>
      </p>
    </div>

    <p className={tw("block", "text-gray-900", "text-sm", "font-semibold")}>
      {country.phonePrefix}
    </p>
  </div>
);

const buttonLabel = (country: PhoneCountry) => (
  <>
    <span>{country.flag}</span>

    <HiChevronDown size={20} className={tw("text-gray-400")} />
  </>
);
