import React from "react";
import { HiCheck, HiX } from "react-icons/hi";

import { TTailwindString, tw } from "utils/tw";

interface Props
  extends Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    "type" | "className"
  > {
  id: string;
  name: string;
  label: string | JSX.Element;
  helpText?: string | JSX.Element;
  errorMessage?: string;
  hideLabel?: boolean;
  isNonClickable?: boolean;
  className?: TTailwindString;
}

export default React.forwardRef(
  (
    {
      id,
      name,
      label,
      helpText,
      errorMessage,
      hideLabel,
      isNonClickable,
      className,
      ...props
    }: React.PropsWithChildren<Props>,
    ref: React.ForwardedRef<HTMLInputElement>
  ): JSX.Element => {
    const hasHelpText = helpText !== undefined;
    const hasError = errorMessage !== undefined;

    const helpTextId = hasHelpText && `${id}-help`;
    const errorId = hasError && `${id}-error`;
    const inputDescriptionIDs =
      hasHelpText || hasError ? `${helpTextId} ${errorId}` : undefined;

    const checkBoxStyles = tw(className, "relative", "flex", "items-center", {
      "pointer-events-none": isNonClickable,
    });
    const toggleStyles = tw(
      props.checked ? "bg-gray-900" : "bg-gray-100",
      "relative",
      "inline-flex",
      "shrink-0",
      "h-6",
      "w-11",
      "border-2",
      "border-transparent",
      "rounded-full",
      "cursor-pointer",
      "transition-colors",
      "ease-in-out",
      "duration-200",
      "focus:outline-none",
      "focus:ring-2",
      "focus:ring-offset-2",
      "focus:ring-gray-900"
    );
    const iconWrapper = tw(
      props.checked ? "translate-x-5" : "translate-x-0",
      "pointer-events-none",
      "relative",
      "inline-block",
      "h-5",
      "w-5",
      "rounded-full",
      "bg-white",
      "shadow",
      "ring-0",
      "transition",
      "ease-in-out",
      "duration-200"
    );
    const iconBase = tw(
      "absolute",
      "inset-0",
      "h-full",
      "w-full",
      "flex",
      "items-center",
      "justify-center",
      "transition-opacity"
    );
    const hiddenIconStyles = tw(
      iconBase,
      "opacity-0",
      "ease-out",
      "duration-100"
    );
    const shownIconStyles = tw(
      iconBase,
      "opacity-100",
      "ease-in",
      "duration-200"
    );

    const labelContainerStyles = tw("text-sm", {
      "ml-3": Boolean(!hideLabel || helpText || errorMessage),
    });
    const labelStyles = tw("text-sm", "text-deepBlue-900", {
      "sr-only": hideLabel,
    });

    return (
      <div>
        <div className={checkBoxStyles}>
          <label className={tw("contents")}>
            <input
              {...props}
              id={id}
              ref={ref}
              name={name}
              type="checkbox"
              aria-invalid={hasError}
              aria-describedby={inputDescriptionIDs}
              readOnly={isNonClickable}
              tabIndex={isNonClickable ? -1 : undefined}
              style={{ display: "none" }}
            />

            <span className={toggleStyles}>
              <span className={iconWrapper}>
                <span
                  className={props.checked ? hiddenIconStyles : shownIconStyles}
                  aria-hidden={props.checked}
                >
                  <HiX size={12} className={tw("text-gray-500")} />
                </span>

                <span
                  className={props.checked ? shownIconStyles : hiddenIconStyles}
                  aria-hidden={!props.checked}
                >
                  <HiCheck size={12} className={tw("text-gray-900")} />
                </span>
              </span>
            </span>
          </label>

          <div className={labelContainerStyles}>
            <label htmlFor={id} className={labelStyles}>
              {label}
            </label>

            {helpTextId && (
              <p id={helpTextId} className={tw("text-deepBlue-500")}>
                {helpText}
              </p>
            )}
          </div>
        </div>

        {errorId && (
          <p id={errorId} className={tw("text-error")}>
            {errorMessage}
          </p>
        )}
      </div>
    );
  }
);
