import { useContext } from "react";

import { Link } from "components/common/basic";
import { PopupVariant, PopupWrapper } from "components/common/popups";
import { Guidelines } from "components/tellmore/composite";
import { AuthContext } from "providers/Authentication";
import { PopupContext } from "providers/PopupHandler";
import { Trans, useTranslation } from "translations";
import { tw } from "utils/tw";

interface Props {
  hasPrevious: boolean;
  variant: PopupVariant;
}

export default (props: Props) => {
  const { t } = useTranslation("tellmore");
  const { closeOnePopup } = useContext(PopupContext);
  const { session } = useContext(AuthContext);

  return (
    <PopupWrapper
      {...props}
      nameSpace="tellmore"
      title={t("wbSettings.guidelines.title", "Routine guidelines")}
      footerActions={[
        {
          id: "popup-close_popup",
          variant: "tertiary",
          onClick: closeOnePopup,
          label: t("wbSettings.guidelines.button.close", "Close"),
        },
      ]}
    >
      <div className={tw("flex", "flex-col", "space-y-6")}>
        <div className={tw("space-y-2")}>
          <h3
            className={tw(
              "text-deepBlue-900",
              "text-lg",
              "font-bold",
              "whitespace-nowrap"
            )}
          >
            {t("wbSettings.guidelines.heading", "Routine guidelines")}
          </h3>

          <p className={tw("text-deepBlue-500", "text-sm")}>
            <Trans
              ns="tellmore"
              i18nKey="wbSettings.guidelines.body"
              defaults="Add your organisation's routines here!
              These routines will be accessible to
              every report handler and will be made
              visible and available for download with
              each incoming report. <0>Download our standard guidelines</0>"
              components={[
                <Link
                  id="wbSettings-downloadGuidelines"
                  to={t(
                    "wbSettings.guidelines.link",
                    "https://en.support.manymore.com"
                  )}
                  isExternal
                />,
              ]}
            />
          </p>
        </div>

        {session && (
          <Guidelines
            routineGuidelines={session.account.routineGuidelines}
            hasEditAccess
            isFullWidth
          />
        )}
      </div>
    </PopupWrapper>
  );
};
