import React from "react";
import { HiChevronDown } from "react-icons/hi";

import { TTailwindString, tw } from "utils/tw";

interface Option {
  value: string | number;
  label: string;
  key?: string;
  isDisabled?: boolean;
}

export interface Props
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  > {
  id: string;
  name: string;
  label: string;
  options: Option[];
  hint?: string;
  helpText?: string;
  errorMessage?: string;
  hideLabel?: boolean;
  isFullWidth?: boolean;
  mobileTextResize?: boolean;
  className?: TTailwindString;
}

export default React.forwardRef(
  (
    {
      id,
      name,
      label,
      options,
      hint,
      helpText,
      errorMessage,
      hideLabel = false,
      isFullWidth = false,
      mobileTextResize = true,
      className,
      ...props
    }: Props,
    ref: React.ForwardedRef<HTMLSelectElement>
  ): JSX.Element => {
    const hasHelpText = helpText !== undefined;
    const hasError = errorMessage !== undefined;

    const helpTextId = hasHelpText && `${id}-help`;
    const errorId = hasError && `${id}-error`;
    const inputDescriptionIDs =
      hasHelpText || hasError ? `${helpTextId} ${errorId}` : undefined;

    const selectContainerStyles = tw(className, "shrink-0", {
      "w-full": isFullWidth,
    });

    const labelContainerStyles = tw("flex", "justify-between", {
      "sr-only": hideLabel,
    });

    const labelStyles = tw("block", "text-sm", "text-gray-700", "mb-1");

    const hintStyles = tw("text-sm", "text-gray-500");

    const inputContainerStyles = tw("relative", "rounded-md", "shadow-sm");

    const inputBase = tw(
      mobileTextResize ? "sm:text-sm" : "text-sm",
      "appearance-none",
      "bg-none",
      "border",
      "block",
      "w-full",
      "shadow-sm",
      "rounded-md",
      "focus:ring-gray-900",
      "focus:border-gray-900",
      "py-2",
      "pr-10"
    );
    const inputError = tw("border-2", "border-error", "bg-error-light");
    const inputDisabled = tw("bg-gray-100", "text-gray-700");
    const inputStyles = tw(inputBase, {
      "border-gray-300": !hasError,
      [inputError as string]: hasError,
      [inputDisabled as string]: props.disabled,
    });

    const iconWrapperStyles = tw(
      "absolute",
      "inset-y-0",
      "flex",
      "items-center",
      "pointer-events-none",
      "right-0",
      "pr-3"
    );

    const iconStyles = tw("h-5", "w-5", {
      "text-gray-400": !hasError,
      "text-error": hasError,
    });

    const textBase = tw("mt-2", "text-sm");
    const helpTextStyles = tw(textBase, "text-gray-700");
    const errorMessageStyles = tw(textBase, "text-error");

    return (
      <div className={selectContainerStyles}>
        <div className={labelContainerStyles}>
          <label htmlFor={id} className={labelStyles}>
            {label}
          </label>

          {hint && <span className={hintStyles}>{hint}</span>}
        </div>

        <div className={inputContainerStyles}>
          <select
            {...props}
            id={id}
            ref={ref}
            name={name}
            className={inputStyles}
            aria-invalid={hasError}
            aria-describedby={inputDescriptionIDs}
          >
            {options.map((option: Option) => (
              <option
                value={option.value}
                key={option.key ?? option.value}
                disabled={option.isDisabled}
              >
                {option.label}
              </option>
            ))}
          </select>

          <div className={iconWrapperStyles}>
            <HiChevronDown className={iconStyles} />
          </div>
        </div>

        {helpTextId && (
          <p className={helpTextStyles} id={helpTextId}>
            {helpText}
          </p>
        )}

        {errorId && (
          <p className={errorMessageStyles} id={errorId}>
            {errorMessage}
          </p>
        )}
      </div>
    );
  }
);
