import { gql } from "@apollo/client";

import {
  ACCOUNT_THEME_FRAGMENT,
  ATTACHMENT_FRAGMENT,
  AccountThemeFragment,
  AttachmentFragment,
  WbSettingsFragment,
} from "graphql/fragments";
import { PublicWbAccount, PublicWbHandler } from "graphql/types";

export const PUBLIC_WB_ACCOUNT_FRAGMENT = gql`
  fragment PublicWbAccountFragment on PublicWbAccount {
    name
    symbolDisplayPath
    logo {
      ...AttachmentFragment
    }
    wbHandlers {
      id
      name
      title
      default
    }
    theme {
      ...AccountThemeFragment
    }
    wbSettings {
      description
      feedbackDescription
      feedbackEnabled
      feedbackLink
      feedbackReadMoreLink
      whistleblowingDescription
      whistleblowingReadMoreLink
    }
  }
  ${ATTACHMENT_FRAGMENT}
  ${ACCOUNT_THEME_FRAGMENT}
`;

export interface PublicWbAccountFragment
  extends Pick<
    PublicWbAccount,
    | "name"
    | "symbolDisplayPath"
    | "logo"
    | "wbHandlers"
    | "theme"
    | "wbSettings"
  > {
  logo?: AttachmentFragment;
  wbHandlers: Pick<PublicWbHandler, "id" | "name" | "title" | "default">[];
  theme: AccountThemeFragment;
  wbSettings: WbSettingsFragment;
}
