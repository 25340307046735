import { gql } from "@apollo/client";

import { WbSettings } from "graphql/types";

export const WB_SETTINGS_FRAGMENT = gql`
  fragment WbSettingsFragment on WbSettings {
    description
    feedbackDescription
    feedbackEnabled
    feedbackLink
    feedbackReadMoreLink
    whistleblowingDescription
    whistleblowingReadMoreLink
  }
`;

export interface WbSettingsFragment
  extends Pick<
    WbSettings,
    | "description"
    | "feedbackDescription"
    | "feedbackEnabled"
    | "feedbackLink"
    | "feedbackReadMoreLink"
    | "whistleblowingDescription"
    | "whistleblowingReadMoreLink"
  > {}
