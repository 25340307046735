import { AccountTheme } from "graphql/types";

interface Props {
  theme: AccountTheme;
  selector?: string;
}

export default ({
  theme: { primary, primaryHover, primaryContrast, font },
  selector = ":root",
}: Props) => (
  <style type="text/css">{`
${font ? `@import url('${font.url}');` : ""}

${selector} {
  ${primary ? `--primary: ${primary};` : ""}
  ${primaryHover ? `--primary-hover: ${primaryHover};` : ""}
  ${primaryContrast ? `--primary-contrast: ${primaryContrast};` : ""}
  ${font ? `--font-family: "${font.name}";` : ""}
  font-family: var(--font-family), "sans-serif";
}`}</style>
);
