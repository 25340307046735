import { lazy } from "react";

const PublicWbReport = lazy(() => import("./PublicWbReport"));
const WbBranding = lazy(() => import("./WbBranding"));
const WbDirectory = lazy(() => import("./WbDirectory"));
const WbLogin = lazy(() => import("./WbLogin"));
const WbSettings = lazy(() => import("./WbSettings"));
const WbReport = lazy(() => import("./WbReport"));
const WbWizard = lazy(() => import("./WbWizard"));

export {
  PublicWbReport,
  WbBranding,
  WbDirectory,
  WbLogin,
  WbSettings,
  WbReport,
  WbWizard,
};
