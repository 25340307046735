import { useContext } from "react";

import Cookie from "js-cookie";

import { AccountTypeEnum, LanguageEnum } from "graphql/types";
import { AuthContext } from "providers/Authentication";
import { IntlContext } from "providers/i18n";
import { getParsedJSON } from "utils";

type CommonRoutes =
  | "HOME"
  | "PRIVACY"
  | "TERMS"
  | "FREELANCER"
  | "TOKEN_HANDLER"
  | "GATE_KEEPER"
  | "BANK_ID_VERIFICATION"
  | "IDENTITY_VERIFICATION"
  | "CRIIPTO_CALLBACK"
  | "REFERRER"
  | "BENEFITS";
type TestRoutes = "ERROR_PAGE" | "SIGNATURE" | "COMPONENTS";
type LoginRoutes =
  | "LOGIN"
  | "SIGNUP_PERSON"
  | "SIGNUP_TEAM"
  | "MAGIC_LINK_LOGIN"
  | "MAGIC_LINK_VERIFICATION"
  | "PLAN_SELECTION"
  | "RESET_PASSWORD"
  | "IFRAME_SIGNUP";
type AccountRoutes =
  | "DASHBOARD"
  | "SCHEDULED_DELETION"
  | "SETTINGS"
  | "SETTINGS_INFO"
  | "SETTINGS_PLAN"
  | "SETTINGS_TEAM"
  | "SETTINGS_TEAM_MEMBERS"
  | "SETTINGS_TEAM_DEPARTMENTS"
  | "SETTINGS_TEAM_ROLES"
  | "SETTINGS_PAYOUT"
  | "SETTINGS_POLICY_DOCUMENTS"
  | "SETTINGS_SUBSCRIPTIONS"
  | "SETTINGS_PAYMENT_METHODS"
  | "OVERVIEW"
  | "OVERVIEW_PEOPLE"
  | "OVERVIEW_CHECKS"
  | "OVERVIEW_DOCUMENTS"
  | "OVERVIEW_REPORTS";
type InvoicemoreRoutes =
  | "PUBLIC_INVOICE"
  | "PAYOUT_SERVICE"
  | "PAYOUT_SERVICE_INVOICES"
  | "PAYOUT_SERVICE_DRAFTS"
  | "PAYOUT_SERVICE_CREDIT_NOTES"
  | "PAYOUT_SERVICE_BY_CLIENT"
  | "PAYOUT_SERVICE_PAYSLIPS"
  | "INVOICE"
  | "INVOICE_CREATION"
  | "INVOICE_DUPLICATION"
  | "CREDIT_NOTE"
  | "CREDIT_NOTE_CREATION"
  | "FREELANCE_PROFILE_WIZARD";
type PayoutmoreRoutes =
  | "PURCHASES"
  | "PURCHASES_ALL"
  | "PURCHASES_REFUNDS"
  | "PURCHASES_BY_SUPPLIER";
type CheckmoreRoutes =
  | "CHECKMORE"
  | "CHECKS"
  | "CHECK_TYPE_SELECTION"
  | "REQUEST_WIZARD"
  | "ONBOARDING_WIZARD"
  | "CHECK_WIZARD";
type RecruitmoreRoutes = "ENROLMENT_WIZARD" | "JOBS" | "JOB" | "JOB_CHECKS";
type SignmoreRoutes =
  | "DOCUMENTS"
  | "DOCUMENT"
  | "AGREEMENTS"
  | "AGREEMENT"
  | "CREATE_DOCUMENT_WIZARD"
  | "SIGN_DOCUMENT_WIZARD"
  | "PUBLIC_DOCUMENT";
type TellmoreRoutes =
  | "WB_DIRECTORY"
  | "WB_SETTINGS"
  | "WB_BRANDING"
  | "WB_REPORT"
  | "WB_LOGIN"
  | "WB_WIZARD"
  | "WB_PUBLIC_REPORT";

export interface RoutesByModule {
  common: CommonRoutes;
  test: TestRoutes;
  login: LoginRoutes;
  account: AccountRoutes;
  invoicemore: InvoicemoreRoutes;
  payoutmore: PayoutmoreRoutes;
  checkmore: CheckmoreRoutes;
  recruitmore: RecruitmoreRoutes;
  signmore: SignmoreRoutes;
  tellmore: TellmoreRoutes;
}
export type Module =
  | "common"
  | "test"
  | "login"
  | "account"
  | "invoicemore"
  | "payoutmore"
  | "checkmore"
  | "recruitmore"
  | "signmore"
  | "tellmore";

enum RouteAuthEnum {
  All = "ALL",
  LoggedOut = "LOGGED_OUT",
  LoggedIn = "LOGGED_IN",
}

const routesByModule: {
  [T in Module]: {
    [K in RoutesByModule[T]]: (
      arg1?: string,
      arg2?: string,
      arg3?: string
    ) => { path: string; auth: RouteAuthEnum };
  };
} = {
  common: {
    HOME: () => ({ path: "home", auth: RouteAuthEnum.All }),
    PRIVACY: () => ({ path: "privacy", auth: RouteAuthEnum.All }),
    TERMS: () => ({ path: "terms", auth: RouteAuthEnum.All }),
    FREELANCER: () => ({ path: "freelancer", auth: RouteAuthEnum.All }),
    TOKEN_HANDLER: (destination) => ({
      path: `token-handler/${destination}`,
      auth: RouteAuthEnum.All,
    }),
    CRIIPTO_CALLBACK: () => ({
      path: "criipto-callback",
      auth: RouteAuthEnum.All,
    }),
    REFERRER: (referrer) => ({
      path: `referrer/${referrer}`,
      auth: RouteAuthEnum.All,
    }),
    GATE_KEEPER: () => ({
      path: "action-required",
      auth: RouteAuthEnum.LoggedIn,
    }),
    BANK_ID_VERIFICATION: () => ({
      path: "bank-id-verification",
      auth: RouteAuthEnum.LoggedIn,
    }),
    IDENTITY_VERIFICATION: () => ({
      path: "alternative-id-verification",
      auth: RouteAuthEnum.LoggedIn,
    }),
    BENEFITS: () => ({
      path: "benefits",
      auth: RouteAuthEnum.LoggedIn,
    }),
  },
  test: {
    ERROR_PAGE: () => ({
      path: "error-page",
      auth: RouteAuthEnum.All,
    }),
    SIGNATURE: () => ({
      path: "signature",
      auth: RouteAuthEnum.All,
    }),
    COMPONENTS: () => ({
      path: "component-library",
      auth: RouteAuthEnum.All,
    }),
  },
  login: {
    LOGIN: () => ({
      path: "existing-user",
      auth: RouteAuthEnum.LoggedOut,
    }),
    SIGNUP_PERSON: () => ({
      path: "new-user",
      auth: RouteAuthEnum.LoggedOut,
    }),
    SIGNUP_TEAM: (plan = "") => ({
      path: plan ? `new-team/${plan}` : "new-team",
      auth: RouteAuthEnum.LoggedOut,
    }),
    RESET_PASSWORD: () => ({
      path: "reset-password",
      auth: RouteAuthEnum.LoggedOut,
    }),
    MAGIC_LINK_LOGIN: () => ({
      path: "magic-link",
      auth: RouteAuthEnum.LoggedOut,
    }),
    MAGIC_LINK_VERIFICATION: () => ({
      path: "magic-link/verification",
      auth: RouteAuthEnum.All,
    }),
    PLAN_SELECTION: (module) => ({
      path: module ? `plans/${module}` : "plans",
      auth: RouteAuthEnum.All,
    }),
    IFRAME_SIGNUP: (vertical = "") => ({
      path: `iframe-signup/${vertical}`,
      auth: RouteAuthEnum.All,
    }),
  },
  account: {
    DASHBOARD: () => ({ path: "dashboard", auth: RouteAuthEnum.LoggedIn }),
    SCHEDULED_DELETION: () => ({
      path: "scheduled-deletion",
      auth: RouteAuthEnum.LoggedIn,
    }),
    SETTINGS: () => ({
      path: "settings",
      auth: RouteAuthEnum.LoggedIn,
    }),
    SETTINGS_INFO: () => ({
      path: "settings/account-info",
      auth: RouteAuthEnum.LoggedIn,
    }),
    SETTINGS_PLAN: () => ({
      path: "settings/payment-plan",
      auth: RouteAuthEnum.LoggedIn,
    }),
    SETTINGS_TEAM: () => ({
      path: "settings/team",
      auth: RouteAuthEnum.LoggedIn,
    }),
    SETTINGS_TEAM_MEMBERS: () => ({
      path: "settings/team/members",
      auth: RouteAuthEnum.LoggedIn,
    }),
    SETTINGS_TEAM_DEPARTMENTS: () => ({
      path: "settings/team/departments",
      auth: RouteAuthEnum.LoggedIn,
    }),
    SETTINGS_TEAM_ROLES: () => ({
      path: "settings/team/roles",
      auth: RouteAuthEnum.LoggedIn,
    }),
    SETTINGS_PAYOUT: () => ({
      path: "settings/payout-settings",
      auth: RouteAuthEnum.LoggedIn,
    }),
    SETTINGS_POLICY_DOCUMENTS: () => ({
      path: "settings/policy-documents",
      auth: RouteAuthEnum.LoggedIn,
    }),
    SETTINGS_SUBSCRIPTIONS: () => ({
      path: "settings/subscriptions",
      auth: RouteAuthEnum.LoggedIn,
    }),
    SETTINGS_PAYMENT_METHODS: () => ({
      path: "settings/payment-methods",
      auth: RouteAuthEnum.LoggedIn,
    }),
    OVERVIEW: () => ({
      path: "overview",
      auth: RouteAuthEnum.LoggedIn,
    }),
    OVERVIEW_PEOPLE: (id) => ({
      path: id ? `overview/people/${id}` : "overview/people",
      auth: RouteAuthEnum.LoggedIn,
    }),
    OVERVIEW_CHECKS: () => ({
      path: "overview/checks",
      auth: RouteAuthEnum.LoggedIn,
    }),
    OVERVIEW_DOCUMENTS: () => ({
      path: "overview/documents",
      auth: RouteAuthEnum.LoggedIn,
    }),
    OVERVIEW_REPORTS: () => ({
      path: "overview/reports",
      auth: RouteAuthEnum.LoggedIn,
    }),
  },
  invoicemore: {
    PUBLIC_INVOICE: (id) => ({
      path: `public-invoice/${id}`,
      auth: RouteAuthEnum.All,
    }),
    PAYOUT_SERVICE: () => ({
      path: "payout-service",
      auth: RouteAuthEnum.LoggedIn,
    }),
    PAYOUT_SERVICE_INVOICES: () => ({
      path: "payout-service/invoices",
      auth: RouteAuthEnum.LoggedIn,
    }),
    PAYOUT_SERVICE_DRAFTS: () => ({
      path: "payout-service/drafts",
      auth: RouteAuthEnum.LoggedIn,
    }),
    PAYOUT_SERVICE_CREDIT_NOTES: () => ({
      path: "payout-service/credit-notes",
      auth: RouteAuthEnum.LoggedIn,
    }),
    PAYOUT_SERVICE_BY_CLIENT: () => ({
      path: "payout-service/by-client",
      auth: RouteAuthEnum.LoggedIn,
    }),
    PAYOUT_SERVICE_PAYSLIPS: () => ({
      path: "payout-service/payslips",
      auth: RouteAuthEnum.LoggedIn,
    }),
    INVOICE: (id) => ({
      path: `invoice/${id}`,
      auth: RouteAuthEnum.LoggedIn,
    }),
    INVOICE_CREATION: (id) => ({
      path: id ? `invoice-creation/${id}` : "invoice-creation",
      auth: RouteAuthEnum.LoggedIn,
    }),
    INVOICE_DUPLICATION: (id) => ({
      path: `invoice-duplication/${id}`,
      auth: RouteAuthEnum.LoggedIn,
    }),
    CREDIT_NOTE: (id) => ({
      path: `credit-note/${id}`,
      auth: RouteAuthEnum.LoggedIn,
    }),
    CREDIT_NOTE_CREATION: (id) => ({
      path: `credit-note-creation/${id}`,
      auth: RouteAuthEnum.LoggedIn,
    }),
    FREELANCE_PROFILE_WIZARD: (id) => ({
      path: id ? `freelance-profile-wizard/${id}` : "freelance-profile-wizard",
      auth: RouteAuthEnum.LoggedIn,
    }),
  },
  payoutmore: {
    PURCHASES: () => ({
      path: "purchases",
      auth: RouteAuthEnum.LoggedIn,
    }),
    PURCHASES_ALL: () => ({
      path: "purchases/all",
      auth: RouteAuthEnum.LoggedIn,
    }),
    PURCHASES_REFUNDS: () => ({
      path: "purchases/refunds",
      auth: RouteAuthEnum.LoggedIn,
    }),
    PURCHASES_BY_SUPPLIER: () => ({
      path: "purchases/by-supplier",
      auth: RouteAuthEnum.LoggedIn,
    }),
  },
  checkmore: {
    CHECKMORE: () => ({
      path: "",
      auth: RouteAuthEnum.LoggedIn,
    }),
    CHECKS: () => ({
      path: "checks",
      auth: RouteAuthEnum.LoggedIn,
    }),
    CHECK_TYPE_SELECTION: () => ({
      path: "invite",
      auth: RouteAuthEnum.LoggedIn,
    }),
    REQUEST_WIZARD: (id, step) => ({
      path: step ? `request-wizard/${id}/${step}` : `request-wizard/${id}`,
      auth: RouteAuthEnum.LoggedIn,
    }),
    CHECK_WIZARD: (id, step) => ({
      path: step ? `check-wizard/${id}/${step}` : `check-wizard/${id}`,
      auth: RouteAuthEnum.LoggedIn,
    }),
    ONBOARDING_WIZARD: (step) => ({
      path: step ? `onboarding-wizard/${step}` : "onboarding-wizard",
      auth: RouteAuthEnum.LoggedIn,
    }),
  },
  recruitmore: {
    ENROLMENT_WIZARD: (jobId) => ({
      path: `enrolment-wizard/${jobId}`,
      auth: RouteAuthEnum.All,
    }),
    JOBS: () => ({
      path: "jobs",
      auth: RouteAuthEnum.LoggedIn,
    }),
    JOB: (id) => ({
      path: `jobs/${id}`,
      auth: RouteAuthEnum.LoggedIn,
    }),
    JOB_CHECKS: () => ({
      path: "verification-checks",
      auth: RouteAuthEnum.LoggedIn,
    }),
  },
  signmore: {
    DOCUMENTS: () => ({
      path: "documents",
      auth: RouteAuthEnum.LoggedIn,
    }),
    DOCUMENT: (id) => ({
      path: `documents/${id}`,
      auth: RouteAuthEnum.LoggedIn,
    }),
    AGREEMENTS: () => ({
      path: `agreements`,
      auth: RouteAuthEnum.LoggedIn,
    }),
    AGREEMENT: (id) => ({
      path: `agreements/${id}`,
      auth: RouteAuthEnum.LoggedIn,
    }),
    CREATE_DOCUMENT_WIZARD: (id, step) => ({
      path: id
        ? step
          ? `create-document-wizard/${id}/${step}`
          : `create-document-wizard/${id}`
        : "create-document-wizard",
      auth: RouteAuthEnum.LoggedIn,
    }),
    SIGN_DOCUMENT_WIZARD: (token, step) => ({
      path: step ? `sign-document/${token}/${step}` : `sign-document/${token}`,
      auth: RouteAuthEnum.LoggedIn,
    }),
    PUBLIC_DOCUMENT: (token) => ({
      path: token ? `public-document/${token}` : "public-document",
      auth: RouteAuthEnum.All,
    }),
  },
  tellmore: {
    WB_DIRECTORY: () => ({
      path: "directory",
      auth: RouteAuthEnum.LoggedIn,
    }),
    WB_SETTINGS: () => ({
      path: "whistleblowing/settings",
      auth: RouteAuthEnum.LoggedIn,
    }),
    WB_BRANDING: () => ({
      path: "whistleblowing/branding",
      auth: RouteAuthEnum.LoggedIn,
    }),
    WB_REPORT: (id) => ({
      path: `whistleblowing/reports/${id}`,
      auth: RouteAuthEnum.LoggedIn,
    }),
    WB_LOGIN: (wbSlug) => ({
      path: `whistleblower-login/${wbSlug}`,
      auth: RouteAuthEnum.All,
    }),
    WB_WIZARD: (wbSlug, step = "0") => ({
      path: `whistleblower-wizard/${wbSlug}/${step}`,
      auth: RouteAuthEnum.All,
    }),
    WB_PUBLIC_REPORT: (wbSlug) => ({
      path: `whistleblower-report/${wbSlug}`,
      auth: RouteAuthEnum.All,
    }),
  },
};

type RouteGetter<R> = <T extends Module>(
  module: T,
  routeName: RoutesByModule[T],
  arg1?: string,
  arg2?: string,
  arg3?: string
) => R;
export const getPath: RouteGetter<{ path: string; auth: RouteAuthEnum }> = (
  module,
  routeName,
  arg1,
  arg2,
  arg3
): { path: string; auth: RouteAuthEnum } =>
  routesByModule[module][routeName](arg1, arg2, arg3);
export const getRoutePath: RouteGetter<string> = (...props) =>
  getPath(...props).path;

export const unspecifiedTypeString = "t";
export const unspecifiedSlugString = "a";

export interface GetRoutePathProps<T extends Module> {
  module: T;
  routeName: RoutesByModule[T];
  arg1?: string;
  arg2?: string;
  arg3?: string;
}
interface RouteOptions {
  isGeneric?: boolean;
  locale?: LanguageEnum;
  account?: {
    slug: string;
    type: AccountTypeEnum;
  };
}
export const useRoutePaths = () => {
  const { currentLocale } = useContext(IntlContext);
  const { session } = useContext(AuthContext);

  return <T extends Module>(
    route: GetRoutePathProps<T>,
    options: RouteOptions = {}
  ) => {
    const locale = options.locale ?? currentLocale;
    const module = route.module;
    const type = options.isGeneric
      ? unspecifiedTypeString
      : (
          options.account?.type ??
          session?.account.accountType ??
          unspecifiedTypeString
        ).toLowerCase();
    const slug = options.isGeneric
      ? unspecifiedSlugString
      : (options.account?.slug ??
        session?.account.slug ??
        unspecifiedSlugString);

    const { auth, path } = getPath(
      module,
      route.routeName,
      route.arg1,
      route.arg2,
      route.arg3
    );

    const components = (() => {
      switch (auth) {
        case RouteAuthEnum.LoggedIn:
          return [getLocalePrefix(locale), type, slug, module, path];

        case RouteAuthEnum.LoggedOut:
          return [getLocalePrefix(locale), module, path];

        default:
          return !options.isGeneric &&
            (options.account?.slug ?? session?.account.id)
            ? [getLocalePrefix(locale), type, slug, module, path]
            : [getLocalePrefix(locale), module, path];
      }
    })();

    return "/v2" + components.join("/");
  };
};

export const getLocalePrefix = (locale: LanguageEnum): string => {
  const localePrefix =
    Object.keys(LanguageEnum).find(
      // TODO: Typecasting should not be necessary here
      (key) => LanguageEnum[key as keyof typeof LanguageEnum] === locale
    ) ?? LanguageEnum.En;

  return `/${localePrefix.toLowerCase()}`;
};

export const useDefaultRoutePath = (): string => {
  const { isLoggedIn, session } = useContext(AuthContext);
  const getRoutePath = useRoutePaths();

  if (!isLoggedIn) return getRoutePath({ module: "common", routeName: "HOME" });
  // TODO: Fix case when session is not available when logged in
  if (!session)
    return getRoutePath({ module: "account", routeName: "DASHBOARD" });

  const lastvisit = getLastVisitFromCookie(session.user.id);
  if (lastvisit) {
    const routeName = getDefaultRouteNameFromModule(lastvisit.module);
    if (routeName) return getRoutePath({ routeName, module: lastvisit.module });
  }

  // Personal account defaults:
  if (session.account.accountType === AccountTypeEnum.Personal) {
    if (session.account.uiFlags.hasReceivedCheckLists === true)
      return getRoutePath({
        module: "checkmore",
        routeName: "CHECKS",
      });

    if (session.hasFreelanceProfiles === true)
      return getRoutePath({
        module: "invoicemore",
        routeName: "INVOICE_CREATION",
      });

    const isPayoutPartner = process.env.REACT_APP_BRAND === "payoutpartner";
    return isPayoutPartner
      ? getRoutePath({ module: "account", routeName: "SETTINGS_PAYOUT" })
      : getRoutePath({ module: "signmore", routeName: "DOCUMENTS" });
  }

  // Team account defaults:
  if (session.account.features.workflows === true)
    return getRoutePath({ module: "recruitmore", routeName: "JOBS" });

  if (session.account.features.checks === true) {
    if (session.account.uiFlags.hasSentCheckLists === true)
      return getRoutePath({
        module: "checkmore",
        routeName: "CHECKS",
      });

    return getRoutePath({
      module: "checkmore",
      routeName: "CHECK_TYPE_SELECTION",
    });
  }

  if (
    session.account.features.invoices === true &&
    session.account.hasFreelanceProfiles
  )
    return getRoutePath({ module: "invoicemore", routeName: "PAYOUT_SERVICE" });

  return getRoutePath({ module: "signmore", routeName: "DOCUMENTS" });
};

const getLastVisitFromCookie = (
  userId: string
): {
  slug: string;
  module: Module;
} | null => {
  const cookie: unknown = Cookie.get("mm-module");
  const parsedCookie = getParsedJSON(cookie) as Record<
    string,
    { slug?: unknown; module?: unknown }
  > | null;
  const unverifiedVisit = parsedCookie?.[userId];
  if (!unverifiedVisit) return null;

  const hasSlug =
    Object.prototype.hasOwnProperty.call(unverifiedVisit, "slug") &&
    typeof unverifiedVisit.slug === "string";

  const hasModule =
    Object.prototype.hasOwnProperty.call(unverifiedVisit, "module") &&
    typeof unverifiedVisit.module === "string";

  if (hasSlug && hasModule) {
    const verifiedVisit = unverifiedVisit as { slug: string; module: Module };

    return verifiedVisit;
  }

  return null;
};

export const getDefaultRouteNameFromModule = (
  module: Module
): RoutesByModule[Module] | null => {
  // prettier-ignore
  switch (module) {
    case "test": return null;
    case "common": return "HOME";
    case "login": return "LOGIN";
    case "account": return "SETTINGS";
    case "payoutmore": return "PURCHASES";
    case "invoicemore": return "PAYOUT_SERVICE";
    case "checkmore": return "CHECKS";
    case "recruitmore": return "JOBS";
    case "signmore": return "DOCUMENTS";
    case "tellmore": return "WB_SETTINGS";

    default: return ((_module: never) => null)(module);
  }
};

export const checkIsRoutePath = <T extends Module>(
  pathname: string,
  { module, routeName, arg1 = "", arg2 = "", arg3 = "" }: GetRoutePathProps<T>
) =>
  pathname.includes(
    getRoutePath(module, routeName, arg1, arg2, arg3).replace(/\/*$/, "")
  );
