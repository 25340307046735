import { RouteProps } from "react-router-dom";

import {
  WbBranding,
  WbDirectory,
  WbLogin,
  WbReport,
  WbSettings,
  WbWizard,
} from "components/tellmore/pages";
import PublicWbReport from "components/tellmore/pages/PublicWbReport";
import { NameSpace } from "translations/shared";
import { getRoutePath } from "utils";

export default (): {
  ns: NameSpace;
  routes: RouteProps[];
  loggedInRoutes: RouteProps[];
  loggedOutRoutes: RouteProps[];
} => {
  // prettier-ignore
  const routes: RouteProps[] = [
    { path: getRoutePath("tellmore", "WB_LOGIN", ":wbSlug"), element: <WbLogin /> },
    { path: getRoutePath("tellmore", "WB_WIZARD", ":wbSlug", ":step"), element: <WbWizard /> },
    { path: getRoutePath("tellmore", "WB_PUBLIC_REPORT", ":wbSlug", ":reportNumber"), element: <PublicWbReport /> },
  ];

  // prettier-ignore
  const loggedInRoutes: RouteProps[] = [
    { path: getRoutePath("tellmore", "WB_DIRECTORY"), element: <WbDirectory /> },
    { path: getRoutePath("tellmore", "WB_SETTINGS"), element: <WbSettings /> },
    { path: getRoutePath("tellmore", "WB_BRANDING"), element: <WbBranding /> },
    { path: getRoutePath("tellmore", "WB_REPORT", ":id"), element: <WbReport /> },
  ];

  // prettier-ignore
  const loggedOutRoutes: RouteProps[] = [];

  return { ns: "tellmore", routes, loggedInRoutes, loggedOutRoutes };
};
