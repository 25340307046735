import { useContext } from "react";
import { HiOutlinePencilAlt } from "react-icons/hi";

import { AccountFragment } from "graphql/fragments";
import { PopupContext } from "providers/PopupHandler";
import { tw } from "utils/tw";

interface Props {
  account: AccountFragment;
  heading: string;
  description: string;
}

export default ({ account, heading, description }: Props): JSX.Element => {
  const { openPopup } = useContext(PopupContext);

  return (
    <div>
      <div className={tw("space-y-6")}>
        <div className={tw("space-y-2")}>
          <div className={tw("flex", "justify-between")}>
            <h2 className={tw("text-lg", "font-bold")}>{heading}</h2>

            {account.canManage && (
              <button
                onClick={() =>
                  openPopup({
                    id: "AccountPhone",
                    props: { phone: account.phoneNumber ?? "" },
                  })
                }
              >
                <HiOutlinePencilAlt />
              </button>
            )}
          </div>

          <p className={tw("text-sm", "text-deepBlue-500")}>{description}</p>
        </div>

        <p className={tw("text-sm", "text-deepBlue-500")}>
          {account.phoneNumber}
        </p>
      </div>
    </div>
  );
};
