export interface PhoneCountry {
  countryCode: string;
  phonePrefix: string;
  flag: string;
  languages: string[];
  currencies: string[];
}

export default (): PhoneCountry[] => [
  {
    countryCode: "NO",
    phonePrefix: "+47",
    flag: "🇳🇴",
    languages: ["no", "nb", "nn"],
    currencies: ["NOK"],
  },
  {
    countryCode: "SE",
    phonePrefix: "+46",
    flag: "🇸🇪",
    languages: ["sv"],
    currencies: ["SEK"],
  },
  {
    countryCode: "DK",
    phonePrefix: "+45",
    flag: "🇩🇰",
    languages: ["da"],
    currencies: ["DKK"],
  },
  {
    countryCode: "FI",
    phonePrefix: "+358",
    flag: "🇫🇮",
    languages: ["fi", "sv"],
    currencies: ["EUR"],
  },
  {
    countryCode: "AD",
    phonePrefix: "+376",
    flag: "🇦🇩",
    languages: ["ca"],
    currencies: ["EUR"],
  },
  {
    countryCode: "AE",
    phonePrefix: "+971",
    flag: "🇦🇪",
    languages: ["ar"],
    currencies: ["AED"],
  },
  {
    countryCode: "AF",
    phonePrefix: "+93",
    flag: "🇦🇫",
    languages: ["ps", "uz", "tk"],
    currencies: ["AFN"],
  },
  {
    countryCode: "AG",
    phonePrefix: "+1268",
    flag: "🇦🇬",
    languages: ["en"],
    currencies: ["XCD"],
  },
  {
    countryCode: "AI",
    phonePrefix: "+1264",
    flag: "🇦🇮",
    languages: ["en"],
    currencies: ["XCD"],
  },
  {
    countryCode: "AL",
    phonePrefix: "+355",
    flag: "🇦🇱",
    languages: ["sq"],
    currencies: ["ALL"],
  },
  {
    countryCode: "AM",
    phonePrefix: "+374",
    flag: "🇦🇲",
    languages: ["hy", "ru"],
    currencies: ["AMD"],
  },
  {
    countryCode: "AO",
    phonePrefix: "+244",
    flag: "🇦🇴",
    languages: ["pt"],
    currencies: ["AOA"],
  },
  {
    countryCode: "AR",
    phonePrefix: "+54",
    flag: "🇦🇷",
    languages: ["es", "gn"],
    currencies: ["ARS"],
  },
  {
    countryCode: "AS",
    phonePrefix: "+1684",
    flag: "🇦🇸",
    languages: ["en", "sm"],
    currencies: ["USD"],
  },
  {
    countryCode: "AT",
    phonePrefix: "+43",
    flag: "🇦🇹",
    languages: ["de"],
    currencies: ["EUR"],
  },
  {
    countryCode: "AU",
    phonePrefix: "+61",
    flag: "🇦🇺",
    languages: ["en"],
    currencies: ["AUD"],
  },
  {
    countryCode: "AW",
    phonePrefix: "+297",
    flag: "🇦🇼",
    languages: ["nl", "pa"],
    currencies: ["AWG"],
  },
  {
    countryCode: "AX",
    phonePrefix: "+358",
    flag: "🇦🇽",
    languages: ["sv"],
    currencies: ["EUR"],
  },
  {
    countryCode: "AZ",
    phonePrefix: "+994",
    flag: "🇦🇿",
    languages: ["az"],
    currencies: ["AZN"],
  },
  {
    countryCode: "BA",
    phonePrefix: "+387",
    flag: "🇧🇦",
    languages: ["bs", "hr", "sr"],
    currencies: ["BAM"],
  },
  {
    countryCode: "BB",
    phonePrefix: "+1246",
    flag: "🇧🇧",
    languages: ["en"],
    currencies: ["BBD"],
  },
  {
    countryCode: "BD",
    phonePrefix: "+880",
    flag: "🇧🇩",
    languages: ["bn"],
    currencies: ["BDT"],
  },
  {
    countryCode: "BE",
    phonePrefix: "+32",
    flag: "🇧🇪",
    languages: ["nl", "fr", "de"],
    currencies: ["EUR"],
  },
  {
    countryCode: "BF",
    phonePrefix: "+226",
    flag: "🇧🇫",
    languages: ["fr", "ff"],
    currencies: ["XOF"],
  },
  {
    countryCode: "BG",
    phonePrefix: "+359",
    flag: "🇧🇬",
    languages: ["bg"],
    currencies: ["BGN"],
  },
  {
    countryCode: "BH",
    phonePrefix: "+973",
    flag: "🇧🇭",
    languages: ["ar"],
    currencies: ["BHD"],
  },
  {
    countryCode: "BI",
    phonePrefix: "+257",
    flag: "🇧🇮",
    languages: ["fr", "rn"],
    currencies: ["BIF"],
  },
  {
    countryCode: "BJ",
    phonePrefix: "+229",
    flag: "🇧🇯",
    languages: ["fr"],
    currencies: ["XOF"],
  },
  {
    countryCode: "BL",
    phonePrefix: "+590",
    flag: "🇧🇱",
    languages: ["fr"],
    currencies: ["EUR"],
  },
  {
    countryCode: "BM",
    phonePrefix: "+1441",
    flag: "🇧🇲",
    languages: ["en"],
    currencies: ["BMD"],
  },
  {
    countryCode: "BN",
    phonePrefix: "+673",
    flag: "🇧🇳",
    languages: ["ms"],
    currencies: ["BND"],
  },
  {
    countryCode: "BO",
    phonePrefix: "+591",
    flag: "🇧🇴",
    languages: ["es", "ay", "qu"],
    currencies: ["BOB", "BOV"],
  },
  {
    countryCode: "BR",
    phonePrefix: "+55",
    flag: "🇧🇷",
    languages: ["pt"],
    currencies: ["BRL"],
  },
  {
    countryCode: "BS",
    phonePrefix: "+1242",
    flag: "🇧🇸",
    languages: ["en"],
    currencies: ["BSD"],
  },
  {
    countryCode: "BT",
    phonePrefix: "+975",
    flag: "🇧🇹",
    languages: ["dz"],
    currencies: ["BTN", "INR"],
  },
  {
    countryCode: "BW",
    phonePrefix: "+267",
    flag: "🇧🇼",
    languages: ["en", "tn"],
    currencies: ["BWP"],
  },
  {
    countryCode: "BY",
    phonePrefix: "+375",
    flag: "🇧🇾",
    languages: ["be", "ru"],
    currencies: ["BYN"],
  },
  {
    countryCode: "BZ",
    phonePrefix: "+501",
    flag: "🇧🇿",
    languages: ["en", "es"],
    currencies: ["BZD"],
  },
  {
    countryCode: "CA",
    phonePrefix: "+1",
    flag: "🇨🇦",
    languages: ["en", "fr"],
    currencies: ["CAD"],
  },
  {
    countryCode: "CC",
    phonePrefix: "+61",
    flag: "🇨🇨",
    languages: ["en"],
    currencies: ["AUD"],
  },
  {
    countryCode: "CD",
    phonePrefix: "+243",
    flag: "🇨🇩",
    languages: ["fr", "ln", "kg", "sw", "lu"],
    currencies: ["CDF"],
  },
  {
    countryCode: "CF",
    phonePrefix: "+236",
    flag: "🇨🇫",
    languages: ["fr", "sg"],
    currencies: ["XAF"],
  },
  {
    countryCode: "CG",
    phonePrefix: "+242",
    flag: "🇨🇬",
    languages: ["fr", "ln"],
    currencies: ["XAF"],
  },
  {
    countryCode: "CH",
    phonePrefix: "+41",
    flag: "🇨🇭",
    languages: ["de", "fr", "it"],
    currencies: ["CHE", "CHF", "CHW"],
  },
  {
    countryCode: "CI",
    phonePrefix: "+225",
    flag: "🇨🇮",
    languages: ["fr"],
    currencies: ["XOF"],
  },
  {
    countryCode: "CK",
    phonePrefix: "+682",
    flag: "🇨🇰",
    languages: ["en"],
    currencies: ["NZD"],
  },
  {
    countryCode: "CL",
    phonePrefix: "+56",
    flag: "🇨🇱",
    languages: ["es"],
    currencies: ["CLF", "CLP"],
  },
  {
    countryCode: "CM",
    phonePrefix: "+237",
    flag: "🇨🇲",
    languages: ["en", "fr"],
    currencies: ["XAF"],
  },
  {
    countryCode: "CN",
    phonePrefix: "+86",
    flag: "🇨🇳",
    languages: ["zh"],
    currencies: ["CNY"],
  },
  {
    countryCode: "CO",
    phonePrefix: "+57",
    flag: "🇨🇴",
    languages: ["es"],
    currencies: ["COP"],
  },
  {
    countryCode: "CR",
    phonePrefix: "+506",
    flag: "🇨🇷",
    languages: ["es"],
    currencies: ["CRC"],
  },
  {
    countryCode: "CU",
    phonePrefix: "+53",
    flag: "🇨🇺",
    languages: ["es"],
    currencies: ["CUC", "CUP"],
  },
  {
    countryCode: "CV",
    phonePrefix: "+238",
    flag: "🇨🇻",
    languages: ["pt"],
    currencies: ["CVE"],
  },
  {
    countryCode: "CX",
    phonePrefix: "+61",
    flag: "🇨🇽",
    languages: ["en"],
    currencies: ["AUD"],
  },
  {
    countryCode: "CY",
    phonePrefix: "+357",
    flag: "🇨🇾",
    languages: ["el", "tr", "hy"],
    currencies: ["EUR"],
  },
  {
    countryCode: "CZ",
    phonePrefix: "+420",
    flag: "🇨🇿",
    languages: ["cs"],
    currencies: ["CZK"],
  },
  {
    countryCode: "DE",
    phonePrefix: "+49",
    flag: "🇩🇪",
    languages: ["de"],
    currencies: ["EUR"],
  },
  {
    countryCode: "DJ",
    phonePrefix: "+253",
    flag: "🇩🇯",
    languages: ["fr", "ar"],
    currencies: ["DJF"],
  },
  {
    countryCode: "DM",
    phonePrefix: "+1767",
    flag: "🇩🇲",
    languages: ["en"],
    currencies: ["XCD"],
  },
  {
    countryCode: "DO",
    phonePrefix: "+1849",
    flag: "🇩🇴",
    languages: ["es"],
    currencies: ["DOP"],
  },
  {
    countryCode: "DZ",
    phonePrefix: "+213",
    flag: "🇩🇿",
    languages: ["ar"],
    currencies: ["DZD"],
  },
  {
    countryCode: "EC",
    phonePrefix: "+593",
    flag: "🇪🇨",
    languages: ["es"],
    currencies: ["USD"],
  },
  {
    countryCode: "EE",
    phonePrefix: "+372",
    flag: "🇪🇪",
    languages: ["et"],
    currencies: ["EUR"],
  },
  {
    countryCode: "EG",
    phonePrefix: "+20",
    flag: "🇪🇬",
    languages: ["ar"],
    currencies: ["EGP"],
  },
  {
    countryCode: "ER",
    phonePrefix: "+291",
    flag: "🇪🇷",
    languages: ["ti", "ar", "en"],
    currencies: ["ERN"],
  },
  {
    countryCode: "ES",
    phonePrefix: "+34",
    flag: "🇪🇸",
    languages: ["es", "eu", "ca", "gl", "oc"],
    currencies: ["EUR"],
  },
  {
    countryCode: "ET",
    phonePrefix: "+251",
    flag: "🇪🇹",
    languages: ["am"],
    currencies: ["ETB"],
  },
  {
    countryCode: "FJ",
    phonePrefix: "+679",
    flag: "🇫🇯",
    languages: ["en", "fj", "hi", "ur"],
    currencies: ["FJD"],
  },
  {
    countryCode: "FK",
    phonePrefix: "+500",
    flag: "🇫🇰",
    languages: ["en"],
    currencies: ["FKP"],
  },
  {
    countryCode: "FM",
    phonePrefix: "+691",
    flag: "🇫🇲",
    languages: ["en"],
    currencies: ["USD"],
  },
  {
    countryCode: "FO",
    phonePrefix: "+298",
    flag: "🇫🇴",
    languages: ["fo"],
    currencies: ["DKK"],
  },
  {
    countryCode: "FR",
    phonePrefix: "+33",
    flag: "🇫🇷",
    languages: ["fr"],
    currencies: ["EUR"],
  },
  {
    countryCode: "GA",
    phonePrefix: "+241",
    flag: "🇬🇦",
    languages: ["fr"],
    currencies: ["XAF"],
  },
  {
    countryCode: "GB",
    phonePrefix: "+44",
    flag: "🇬🇧",
    languages: ["en"],
    currencies: ["GBP"],
  },
  {
    countryCode: "GD",
    phonePrefix: "+1473",
    flag: "🇬🇩",
    languages: ["en"],
    currencies: ["XCD"],
  },
  {
    countryCode: "GE",
    phonePrefix: "+995",
    flag: "🇬🇪",
    languages: ["ka"],
    currencies: ["GEL"],
  },
  {
    countryCode: "GF",
    phonePrefix: "+594",
    flag: "🇬🇫",
    languages: ["fr"],
    currencies: ["EUR"],
  },
  {
    countryCode: "GG",
    phonePrefix: "+44",
    flag: "🇬🇬",
    languages: ["en", "fr"],
    currencies: ["GBP"],
  },
  {
    countryCode: "GH",
    phonePrefix: "+233",
    flag: "🇬🇭",
    languages: ["en"],
    currencies: ["GHS"],
  },
  {
    countryCode: "GI",
    phonePrefix: "+350",
    flag: "🇬🇮",
    languages: ["en"],
    currencies: ["GIP"],
  },
  {
    countryCode: "GL",
    phonePrefix: "+299",
    flag: "🇬🇱",
    languages: ["kl"],
    currencies: ["DKK"],
  },
  {
    countryCode: "GM",
    phonePrefix: "+220",
    flag: "🇬🇲",
    languages: ["en"],
    currencies: ["GMD"],
  },
  {
    countryCode: "GN",
    phonePrefix: "+224",
    flag: "🇬🇳",
    languages: ["fr", "ff"],
    currencies: ["GNF"],
  },
  {
    countryCode: "GP",
    phonePrefix: "+590",
    flag: "🇬🇵",
    languages: ["fr"],
    currencies: ["EUR"],
  },
  {
    countryCode: "GQ",
    phonePrefix: "+240",
    flag: "🇬🇶",
    languages: ["es", "fr"],
    currencies: ["XAF"],
  },
  {
    countryCode: "GR",
    phonePrefix: "+30",
    flag: "🇬🇷",
    languages: ["el"],
    currencies: ["EUR"],
  },
  {
    countryCode: "GS",
    phonePrefix: "+500",
    flag: "🇬🇸",
    languages: ["en"],
    currencies: ["GBP"],
  },
  {
    countryCode: "GT",
    phonePrefix: "+502",
    flag: "🇬🇹",
    languages: ["es"],
    currencies: ["GTQ"],
  },
  {
    countryCode: "GU",
    phonePrefix: "+1671",
    flag: "🇬🇺",
    languages: ["en", "ch", "es"],
    currencies: ["USD"],
  },
  {
    countryCode: "GW",
    phonePrefix: "+245",
    flag: "🇬🇼",
    languages: ["pt"],
    currencies: ["XOF"],
  },
  {
    countryCode: "GY",
    phonePrefix: "+595",
    flag: "🇬🇾",
    languages: ["en"],
    currencies: ["GYD"],
  },
  {
    countryCode: "HK",
    phonePrefix: "+852",
    flag: "🇭🇰",
    languages: ["zh", "en"],
    currencies: ["HKD"],
  },
  {
    countryCode: "HN",
    phonePrefix: "+504",
    flag: "🇭🇳",
    languages: ["es"],
    currencies: ["HNL"],
  },
  {
    countryCode: "HR",
    phonePrefix: "+385",
    flag: "🇭🇷",
    languages: ["hr"],
    currencies: ["EUR"],
  },
  {
    countryCode: "HT",
    phonePrefix: "+509",
    flag: "🇭🇹",
    languages: ["fr", "ht"],
    currencies: ["HTG", "USD"],
  },
  {
    countryCode: "HU",
    phonePrefix: "+36",
    flag: "🇭🇺",
    languages: ["hu"],
    currencies: ["HUF"],
  },
  {
    countryCode: "ID",
    phonePrefix: "+62",
    flag: "🇮🇩",
    languages: ["id"],
    currencies: ["IDR"],
  },
  {
    countryCode: "IE",
    phonePrefix: "+353",
    flag: "🇮🇪",
    languages: ["ga", "en"],
    currencies: ["EUR"],
  },
  {
    countryCode: "IL",
    phonePrefix: "+972",
    flag: "🇮🇱",
    languages: ["he", "ar"],
    currencies: ["ILS"],
  },
  {
    countryCode: "IM",
    phonePrefix: "+44",
    flag: "🇮🇲",
    languages: ["en", "gv"],
    currencies: ["GBP"],
  },
  {
    countryCode: "IN",
    phonePrefix: "+91",
    flag: "🇮🇳",
    languages: ["hi", "en"],
    currencies: ["INR"],
  },
  {
    countryCode: "IO",
    phonePrefix: "+246",
    flag: "🇮🇴",
    languages: ["en"],
    currencies: ["USD"],
  },
  {
    countryCode: "IQ",
    phonePrefix: "+964",
    flag: "🇮🇷",
    languages: ["ar", "ku"],
    currencies: ["IQD"],
  },
  {
    countryCode: "IR",
    phonePrefix: "+98",
    flag: "🇮🇷",
    languages: ["fa"],
    currencies: ["IRR"],
  },
  {
    countryCode: "IS",
    phonePrefix: "+354",
    flag: "🇮🇸",
    languages: ["is"],
    currencies: ["ISK"],
  },
  {
    countryCode: "IT",
    phonePrefix: "+39",
    flag: "🇮🇹",
    languages: ["it"],
    currencies: ["EUR"],
  },
  {
    countryCode: "JE",
    phonePrefix: "+44",
    flag: "🇯🇪",
    languages: ["en", "fr"],
    currencies: ["GBP"],
  },
  {
    countryCode: "JM",
    phonePrefix: "+1876",
    flag: "🇯🇲",
    languages: ["en"],
    currencies: ["JMD"],
  },
  {
    countryCode: "JO",
    phonePrefix: "+962",
    flag: "🇯🇴",
    languages: ["ar"],
    currencies: ["JOD"],
  },
  {
    countryCode: "JP",
    phonePrefix: "+81",
    flag: "🇯🇵",
    languages: ["ja"],
    currencies: ["JPY"],
  },
  {
    countryCode: "KE",
    phonePrefix: "+254",
    flag: "🇰🇪",
    languages: ["en", "sw"],
    currencies: ["KES"],
  },
  {
    countryCode: "KG",
    phonePrefix: "+996",
    flag: "🇰🇬",
    languages: ["ky", "ru"],
    currencies: ["KGS"],
  },
  {
    countryCode: "KH",
    phonePrefix: "+855",
    flag: "🇰🇭",
    languages: ["km"],
    currencies: ["KHR"],
  },
  {
    countryCode: "KI",
    phonePrefix: "+686",
    flag: "🇰🇮",
    languages: ["en"],
    currencies: ["AUD"],
  },
  {
    countryCode: "KM",
    phonePrefix: "+269",
    flag: "🇰🇲",
    languages: ["ar", "fr"],
    currencies: ["KMF"],
  },
  {
    countryCode: "KN",
    phonePrefix: "+1869",
    flag: "🇰🇳",
    languages: ["en"],
    currencies: ["XCD"],
  },
  {
    countryCode: "KP",
    phonePrefix: "+850",
    flag: "🇰🇵",
    languages: ["ko"],
    currencies: ["KPW"],
  },
  {
    countryCode: "KR",
    phonePrefix: "+82",
    flag: "🇰🇷",
    languages: ["ko"],
    currencies: ["KRW"],
  },
  {
    countryCode: "KW",
    phonePrefix: "+965",
    flag: "🇰🇼",
    languages: ["ar"],
    currencies: ["KWD"],
  },
  {
    countryCode: "KY",
    phonePrefix: "+345",
    flag: "🇰🇾",
    languages: ["en"],
    currencies: ["KYD"],
  },
  {
    countryCode: "KZ",
    phonePrefix: "+77",
    flag: "🇰🇿",
    languages: ["kk", "ru"],
    currencies: ["KZT"],
  },
  {
    countryCode: "LA",
    phonePrefix: "+856",
    flag: "🇱🇦",
    languages: ["lo"],
    currencies: ["LAK"],
  },
  {
    countryCode: "LB",
    phonePrefix: "+961",
    flag: "🇱🇧",
    languages: ["ar", "fr"],
    currencies: ["LBP"],
  },
  {
    countryCode: "LC",
    phonePrefix: "+1758",
    flag: "🇱🇨",
    languages: ["en"],
    currencies: ["XCD"],
  },
  {
    countryCode: "LI",
    phonePrefix: "+423",
    flag: "🇱🇮",
    languages: ["de"],
    currencies: ["CHF"],
  },
  {
    countryCode: "LK",
    phonePrefix: "+94",
    flag: "🇱🇰",
    languages: ["si", "ta"],
    currencies: ["LKR"],
  },
  {
    countryCode: "LR",
    phonePrefix: "+231",
    flag: "🇱🇷",
    languages: ["en"],
    currencies: ["LRD"],
  },
  {
    countryCode: "LS",
    phonePrefix: "+266",
    flag: "🇱🇸",
    languages: ["en", "st"],
    currencies: ["LSL", "ZAR"],
  },
  {
    countryCode: "LT",
    phonePrefix: "+370",
    flag: "🇱🇹",
    languages: ["lt"],
    currencies: ["EUR"],
  },
  {
    countryCode: "LU",
    phonePrefix: "+352",
    flag: "🇱🇺",
    languages: ["fr", "de", "lb"],
    currencies: ["EUR"],
  },
  {
    countryCode: "LV",
    phonePrefix: "+371",
    flag: "🇱🇻",
    languages: ["lv"],
    currencies: ["EUR"],
  },
  {
    countryCode: "LY",
    phonePrefix: "+218",
    flag: "🇱🇾",
    languages: ["ar"],
    currencies: ["LYD"],
  },
  {
    countryCode: "MA",
    phonePrefix: "+212",
    flag: "🇲🇦",
    languages: ["ar"],
    currencies: ["MAD"],
  },
  {
    countryCode: "MC",
    phonePrefix: "+377",
    flag: "🇲🇨",
    languages: ["fr"],
    currencies: ["EUR"],
  },
  {
    countryCode: "MD",
    phonePrefix: "+373",
    flag: "🇲🇩",
    languages: ["ro"],
    currencies: ["MDL"],
  },
  {
    countryCode: "ME",
    phonePrefix: "+382",
    flag: "🇲🇪",
    languages: ["sr", "bs", "sq", "hr"],
    currencies: ["EUR"],
  },
  {
    countryCode: "MF",
    phonePrefix: "+590",
    flag: "🇲🇫",
    languages: ["en", "fr", "nl"],
    currencies: ["EUR"],
  },
  {
    countryCode: "MG",
    phonePrefix: "+261",
    flag: "🇲🇬",
    languages: ["fr", "mg"],
    currencies: ["MGA"],
  },
  {
    countryCode: "MH",
    phonePrefix: "+692",
    flag: "🇲🇭",
    languages: ["en", "mh"],
    currencies: ["USD"],
  },
  {
    countryCode: "MK",
    phonePrefix: "+389",
    flag: "🇲🇰",
    languages: ["mk"],
    currencies: ["MKD"],
  },
  {
    countryCode: "ML",
    phonePrefix: "+223",
    flag: "🇲🇱",
    languages: ["fr"],
    currencies: ["XOF"],
  },
  {
    countryCode: "MM",
    phonePrefix: "+95",
    flag: "🇲🇲",
    languages: ["my"],
    currencies: ["MMK"],
  },
  {
    countryCode: "MN",
    phonePrefix: "+976",
    flag: "🇲🇳",
    languages: ["mn"],
    currencies: ["MNT"],
  },
  {
    countryCode: "MO",
    phonePrefix: "+853",
    flag: "🇲🇴",
    languages: ["zh", "pt"],
    currencies: ["MOP"],
  },
  {
    countryCode: "MP",
    phonePrefix: "+1670",
    flag: "🇲🇵",
    languages: ["en", "ch"],
    currencies: ["USD"],
  },
  {
    countryCode: "MQ",
    phonePrefix: "+596",
    flag: "🇲🇶",
    languages: ["fr"],
    currencies: ["EUR"],
  },
  {
    countryCode: "MR",
    phonePrefix: "+222",
    flag: "🇲🇷",
    languages: ["ar"],
    currencies: ["MRU"],
  },
  {
    countryCode: "MS",
    phonePrefix: "+1664",
    flag: "🇲🇸",
    languages: ["en"],
    currencies: ["XCD"],
  },
  {
    countryCode: "MT",
    phonePrefix: "+356",
    flag: "🇲🇹",
    languages: ["mt", "en"],
    currencies: ["EUR"],
  },
  {
    countryCode: "MU",
    phonePrefix: "+230",
    flag: "🇲🇺",
    languages: ["en"],
    currencies: ["MUR"],
  },
  {
    countryCode: "MV",
    phonePrefix: "+960",
    flag: "🇲🇻",
    languages: ["dv"],
    currencies: ["MVR"],
  },
  {
    countryCode: "MW",
    phonePrefix: "+265",
    flag: "🇲🇼",
    languages: ["en", "ny"],
    currencies: ["MWK"],
  },
  {
    countryCode: "MX",
    phonePrefix: "+52",
    flag: "🇲🇽",
    languages: ["es"],
    currencies: ["MXN"],
  },
  {
    countryCode: "MY",
    phonePrefix: "+60",
    flag: "🇲🇾",
    languages: ["ms"],
    currencies: ["MYR"],
  },
  {
    countryCode: "MZ",
    phonePrefix: "+258",
    flag: "🇲🇿",
    languages: ["pt"],
    currencies: ["MZN"],
  },
  {
    countryCode: "NA",
    phonePrefix: "+264",
    flag: "🇳🇦",
    languages: ["en", "af"],
    currencies: ["NAD", "ZAR"],
  },
  {
    countryCode: "NC",
    phonePrefix: "+687",
    flag: "🇳🇨",
    languages: ["fr"],
    currencies: ["XPF"],
  },
  {
    countryCode: "NE",
    phonePrefix: "+227",
    flag: "🇳🇪",
    languages: ["fr"],
    currencies: ["XOF"],
  },
  {
    countryCode: "NF",
    phonePrefix: "+672",
    flag: "🇳🇫",
    languages: ["en"],
    currencies: ["AUD"],
  },
  {
    countryCode: "NG",
    phonePrefix: "+234",
    flag: "🇳🇬",
    languages: ["en"],
    currencies: ["NGN"],
  },
  {
    countryCode: "NI",
    phonePrefix: "+505",
    flag: "🇳🇮",
    languages: ["es"],
    currencies: ["NIO"],
  },
  {
    countryCode: "NL",
    phonePrefix: "+31",
    flag: "🇳🇱",
    languages: ["nl"],
    currencies: ["EUR"],
  },
  {
    countryCode: "NP",
    phonePrefix: "+977",
    flag: "🇳🇵",
    languages: ["ne"],
    currencies: ["NPR"],
  },
  {
    countryCode: "NR",
    phonePrefix: "+674",
    flag: "🇳🇷",
    languages: ["en", "na"],
    currencies: ["AUD"],
  },
  {
    countryCode: "NU",
    phonePrefix: "+683",
    flag: "🇳🇺",
    languages: ["en"],
    currencies: ["NZD"],
  },
  {
    countryCode: "NZ",
    phonePrefix: "+64",
    flag: "🇳🇿",
    languages: ["en", "mi"],
    currencies: ["NZD"],
  },
  {
    countryCode: "OM",
    phonePrefix: "+968",
    flag: "🇴🇲",
    languages: ["ar"],
    currencies: ["OMR"],
  },
  {
    countryCode: "PA",
    phonePrefix: "+507",
    flag: "🇵🇦",
    languages: ["es"],
    currencies: ["PAB", "USD"],
  },
  {
    countryCode: "PE",
    phonePrefix: "+51",
    flag: "🇵🇪",
    languages: ["es"],
    currencies: ["PEN"],
  },
  {
    countryCode: "PF",
    phonePrefix: "+689",
    flag: "🇵🇫",
    languages: ["fr"],
    currencies: ["XPF"],
  },
  {
    countryCode: "PG",
    phonePrefix: "+675",
    flag: "🇵🇬",
    languages: ["en"],
    currencies: ["PGK"],
  },
  {
    countryCode: "PH",
    phonePrefix: "+63",
    flag: "🇵🇭",
    languages: ["en"],
    currencies: ["PHP"],
  },
  {
    countryCode: "PK",
    phonePrefix: "+92",
    flag: "🇵🇰",
    languages: ["en", "ur"],
    currencies: ["PKR"],
  },
  {
    countryCode: "PL",
    phonePrefix: "+48",
    flag: "🇵🇱",
    languages: ["pl"],
    currencies: ["PLN"],
  },
  {
    countryCode: "PM",
    phonePrefix: "+508",
    flag: "🇵🇲",
    languages: ["fr"],
    currencies: ["EUR"],
  },
  {
    countryCode: "PN",
    phonePrefix: "+872",
    flag: "🇵🇳",
    languages: ["en"],
    currencies: ["NZD"],
  },
  {
    countryCode: "PR",
    phonePrefix: "+1939",
    flag: "🇵🇷",
    languages: ["es", "en"],
    currencies: ["USD"],
  },
  {
    countryCode: "PS",
    phonePrefix: "+970",
    flag: "🇵🇸",
    languages: ["ar"],
    currencies: ["ILS"],
  },
  {
    countryCode: "PT",
    phonePrefix: "+351",
    flag: "🇵🇹",
    languages: ["pt"],
    currencies: ["EUR"],
  },
  {
    countryCode: "PW",
    phonePrefix: "+680",
    flag: "🇵🇼",
    languages: ["en"],
    currencies: ["USD"],
  },
  {
    countryCode: "PY",
    phonePrefix: "+595",
    flag: "🇵🇾",
    languages: ["es", "gn"],
    currencies: ["PYG"],
  },
  {
    countryCode: "QA",
    phonePrefix: "+974",
    flag: "🇶🇦",
    languages: ["ar"],
    currencies: ["QAR"],
  },
  {
    countryCode: "RE",
    phonePrefix: "+262",
    flag: "🇷🇪",
    languages: ["fr"],
    currencies: ["EUR"],
  },
  {
    countryCode: "RO",
    phonePrefix: "+40",
    flag: "🇷🇴",
    languages: ["ro"],
    currencies: ["RON"],
  },
  {
    countryCode: "RS",
    phonePrefix: "+381",
    flag: "🇷🇸",
    languages: ["sr"],
    currencies: ["RSD"],
  },
  {
    countryCode: "RU",
    phonePrefix: "+7",
    flag: "🇷🇺",
    languages: ["ru"],
    currencies: ["RUB"],
  },
  {
    countryCode: "RW",
    phonePrefix: "+250",
    flag: "🇷🇼",
    languages: ["rw", "en", "fr"],
    currencies: ["RWF"],
  },
  {
    countryCode: "SA",
    phonePrefix: "+966",
    flag: "🇸🇦",
    languages: ["ar"],
    currencies: ["SAR"],
  },
  {
    countryCode: "SB",
    phonePrefix: "+677",
    flag: "🇸🇧",
    languages: ["en"],
    currencies: ["SBD"],
  },
  {
    countryCode: "SC",
    phonePrefix: "+248",
    flag: "🇸🇨",
    languages: ["fr", "en"],
    currencies: ["SCR"],
  },
  {
    countryCode: "SD",
    phonePrefix: "+249",
    flag: "🇸🇩",
    languages: ["ar", "en"],
    currencies: ["SDG"],
  },
  {
    countryCode: "SG",
    phonePrefix: "+65",
    flag: "🇸🇬",
    languages: ["en", "ms", "ta", "zh"],
    currencies: ["SGD"],
  },
  {
    countryCode: "SH",
    phonePrefix: "+290",
    flag: "🇸🇭",
    languages: ["en"],
    currencies: ["SHP"],
  },
  {
    countryCode: "SI",
    phonePrefix: "+386",
    flag: "🇸🇮",
    languages: ["sl"],
    currencies: ["EUR"],
  },
  {
    countryCode: "SJ",
    phonePrefix: "+47",
    flag: "🇸🇯",
    languages: ["no"],
    currencies: ["NOK"],
  },
  {
    countryCode: "SK",
    phonePrefix: "+421",
    flag: "🇸🇰",
    languages: ["sk"],
    currencies: ["EUR"],
  },
  {
    countryCode: "SL",
    phonePrefix: "+232",
    flag: "🇸🇱",
    languages: ["en"],
    currencies: ["SLL"],
  },
  {
    countryCode: "SM",
    phonePrefix: "+378",
    flag: "🇸🇲",
    languages: ["it"],
    currencies: ["EUR"],
  },
  {
    countryCode: "SN",
    phonePrefix: "+221",
    flag: "🇸🇳",
    languages: ["fr"],
    currencies: ["XOF"],
  },
  {
    countryCode: "SO",
    phonePrefix: "+252",
    flag: "🇸🇴",
    languages: ["so", "ar"],
    currencies: ["SOS"],
  },
  {
    countryCode: "SR",
    phonePrefix: "+597",
    flag: "🇸🇷",
    languages: ["nl"],
    currencies: ["SRD"],
  },
  {
    countryCode: "SS",
    phonePrefix: "+211",
    flag: "🇸🇸",
    languages: ["en"],
    currencies: ["SSP"],
  },
  {
    countryCode: "ST",
    phonePrefix: "+239",
    flag: "🇸🇹",
    languages: ["pt"],
    currencies: ["STN"],
  },
  {
    countryCode: "SV",
    phonePrefix: "+503",
    flag: "🇸🇻",
    languages: ["es"],
    currencies: ["SVC", "USD"],
  },
  {
    countryCode: "SY",
    phonePrefix: "+963",
    flag: "🇸🇾",
    languages: ["ar"],
    currencies: ["SYP"],
  },
  {
    countryCode: "SZ",
    phonePrefix: "+268",
    flag: "🇸🇿",
    languages: ["en", "ss"],
    currencies: ["SZL"],
  },
  {
    countryCode: "TC",
    phonePrefix: "+1649",
    flag: "🇹🇨",
    languages: ["en"],
    currencies: ["USD"],
  },
  {
    countryCode: "TD",
    phonePrefix: "+235",
    flag: "🇹🇩",
    languages: ["fr", "ar"],
    currencies: ["XAF"],
  },
  {
    countryCode: "TG",
    phonePrefix: "+228",
    flag: "🇹🇬",
    languages: ["fr"],
    currencies: ["XOF"],
  },
  {
    countryCode: "TH",
    phonePrefix: "+66",
    flag: "🇹🇭",
    languages: ["th"],
    currencies: ["THB"],
  },
  {
    countryCode: "TJ",
    phonePrefix: "+992",
    flag: "🇹🇯",
    languages: ["tg", "ru"],
    currencies: ["TJS"],
  },
  {
    countryCode: "TK",
    phonePrefix: "+690",
    flag: "🇹🇰",
    languages: ["en"],
    currencies: ["NZD"],
  },
  {
    countryCode: "TL",
    phonePrefix: "+670",
    flag: "🇹🇱",
    languages: ["pt"],
    currencies: ["USD"],
  },
  {
    countryCode: "TM",
    phonePrefix: "+993",
    flag: "🇹🇲",
    languages: ["tk", "ru"],
    currencies: ["TMT"],
  },
  {
    countryCode: "TN",
    phonePrefix: "+216",
    flag: "🇹🇳",
    languages: ["ar"],
    currencies: ["TND"],
  },
  {
    countryCode: "TO",
    phonePrefix: "+676",
    flag: "🇹🇴",
    languages: ["en", "to"],
    currencies: ["TOP"],
  },
  {
    countryCode: "TR",
    phonePrefix: "+90",
    flag: "🇹🇷",
    languages: ["tr"],
    currencies: ["TRY"],
  },
  {
    countryCode: "TT",
    phonePrefix: "+1868",
    flag: "🇹🇹",
    languages: ["en"],
    currencies: ["TTD"],
  },
  {
    countryCode: "TV",
    phonePrefix: "+688",
    flag: "🇹🇻",
    languages: ["en"],
    currencies: ["AUD"],
  },
  {
    countryCode: "TW",
    phonePrefix: "+886",
    flag: "🇹🇼",
    languages: ["zh"],
    currencies: ["TWD"],
  },
  {
    countryCode: "TZ",
    phonePrefix: "+255",
    flag: "🇹🇿",
    languages: ["sw", "en"],
    currencies: ["TZS"],
  },
  {
    countryCode: "UA",
    phonePrefix: "+380",
    flag: "🇺🇦",
    languages: ["uk"],
    currencies: ["UAH"],
  },
  {
    countryCode: "UG",
    phonePrefix: "+256",
    flag: "🇺🇬",
    languages: ["en", "sw"],
    currencies: ["UGX"],
  },
  {
    countryCode: "US",
    phonePrefix: "+1",
    flag: "🇺🇸",
    languages: ["en"],
    currencies: ["USD", "USN", "USS"],
  },
  {
    countryCode: "UY",
    phonePrefix: "+598",
    flag: "🇺🇾",
    languages: ["es"],
    currencies: ["UYI", "UYU"],
  },
  {
    countryCode: "UZ",
    phonePrefix: "+998",
    flag: "🇺🇿",
    languages: ["uz", "ru"],
    currencies: ["UZS"],
  },
  {
    countryCode: "VA",
    phonePrefix: "+379",
    flag: "🇻🇦",
    languages: ["it", "la"],
    currencies: ["EUR"],
  },
  {
    countryCode: "VC",
    phonePrefix: "+1784",
    flag: "🇻🇨",
    languages: ["en"],
    currencies: ["XCD"],
  },
  {
    countryCode: "VE",
    phonePrefix: "+58",
    flag: "🇻🇪",
    languages: ["es"],
    currencies: ["VES"],
  },
  {
    countryCode: "VG",
    phonePrefix: "+1284",
    flag: "🇻🇬",
    languages: ["en"],
    currencies: ["USD"],
  },
  {
    countryCode: "VI",
    phonePrefix: "+1340",
    flag: "🇻🇮",
    languages: ["en"],
    currencies: ["USD"],
  },
  {
    countryCode: "VN",
    phonePrefix: "+84",
    flag: "🇻🇳",
    languages: ["vi"],
    currencies: ["VND"],
  },
  {
    countryCode: "VU",
    phonePrefix: "+678",
    flag: "🇻🇺",
    languages: ["bi", "en", "fr"],
    currencies: ["VUV"],
  },
  {
    countryCode: "WF",
    phonePrefix: "+681",
    flag: "🇼🇫",
    languages: ["fr"],
    currencies: ["XPF"],
  },
  {
    countryCode: "WS",
    phonePrefix: "+685",
    flag: "🇼🇸",
    languages: ["sm", "en"],
    currencies: ["WST"],
  },
  {
    countryCode: "YE",
    phonePrefix: "+967",
    flag: "🇾🇪",
    languages: ["ar"],
    currencies: ["YER"],
  },
  {
    countryCode: "YT",
    phonePrefix: "+262",
    flag: "🇾🇹",
    languages: ["fr"],
    currencies: ["EUR"],
  },
  {
    countryCode: "ZA",
    phonePrefix: "+27",
    flag: "🇿🇦",
    languages: ["af", "en", "nr", "st", "ss", "tn", "ts", "ve", "xh", "zu"],
    currencies: ["ZAR"],
  },
  {
    countryCode: "ZM",
    phonePrefix: "+260",
    flag: "🇿🇲",
    languages: ["en"],
    currencies: ["ZMW"],
  },
  {
    countryCode: "ZW",
    phonePrefix: "+263",
    flag: "🇿🇼",
    languages: ["en", "sn", "nd"],
    currencies: ["USD", "ZAR", "BWP", "GBP", "AUD", "CNY", "INR", "JPY"],
  },
];
