import { Icon, IconButton, IconType, Link } from "components/common/basic";
import { IconButtonProps } from "components/common/basic/IconButton";
import { TTextColor } from "tw-generated";
import { tw } from "utils/tw";

export type HeaderAction = Omit<IconButtonProps, "ref" | "size"> & {
  to?: string;
  isExternal?: boolean;
};

export interface PopupHeaderProps {
  title: string;
  subTitle?: string | JSX.Element;
  onClose?: () => void;
  goBack?: () => void;
  headerActions?: HeaderAction[];
  leadingIcon?: IconType;
  leadingIconIsFilled?: boolean;
  leadingIconSize?: number;
  leadingIconColor?: TTextColor;
}

export default ({
  title,
  subTitle,
  onClose,
  goBack,
  headerActions,
  leadingIcon = goBack && "HiChevronLeft",
  leadingIconIsFilled = Boolean(goBack),
  leadingIconSize = 28,
  leadingIconColor = "text-deepBlue-900",
}: PopupHeaderProps) => {
  const titleSection = (
    <>
      {leadingIcon && (
        <Icon
          icon={leadingIcon}
          isFilled={leadingIconIsFilled}
          size={leadingIconSize}
          color={leadingIconColor}
          margin="my-1"
        />
      )}

      <h1
        id="popupHeaderTitle"
        className={tw(
          "text-left",
          "text-2xl",
          "font-bold",
          "text-deepBlue-900",
          "scroll-mt-6"
        )}
      >
        {title}
      </h1>
    </>
  );

  return (
    <div className={tw("space-y-6")}>
      <div
        className={tw("flex", "space-x-4", "justify-between", "items-start")}
      >
        {goBack ? (
          <button
            onClick={goBack}
            className={tw("flex", "space-x-4", "hover:opacity-80")}
          >
            {titleSection}
          </button>
        ) : (
          <div className={tw("flex", "space-x-4")}>
            {titleSection}

            {headerActions && (
              <div className={tw("flex", "space-x-2")}>
                {headerActions.map(({ to, isExternal, ...buttonProps }) => {
                  if (to)
                    return (
                      <Link
                        id={buttonProps.id}
                        key={buttonProps.id}
                        to={to}
                        isExternal={isExternal}
                      >
                        <IconButton {...buttonProps} />
                      </Link>
                    );

                  return <IconButton {...buttonProps} key={buttonProps.id} />;
                })}
              </div>
            )}
          </div>
        )}

        {onClose && (
          <IconButton
            id="popup-close_popup"
            icon="HiX"
            isFilled
            onClick={onClose}
          />
        )}
      </div>

      {subTitle && (
        <h2
          className={tw("text-lg", "text-deepBlue-700", "whitespace-pre-wrap")}
        >
          {subTitle}
        </h2>
      )}
    </div>
  );
};
