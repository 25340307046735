import { AccountTheme } from "graphql/types";

export const fonts = [
  {
    name: "Abril Fatface",
    url: "https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap",
  },
  {
    name: "Alegreya",
    url: "https://fonts.googleapis.com/css2?family=Alegreya:wght@400;600;700;800&display=swap",
  },
  {
    name: "Alegreya Sans",
    url: "https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@400;600;700;800&display=swap",
  },
  {
    name: "Anton",
    url: "https://fonts.googleapis.com/css2?family=Anton&display=swap",
  },
  { name: "Arial", url: "" }, // Not available on https://fonts.google.com/, but considered web safe
  {
    name: "Arimo",
    url: "https://fonts.googleapis.com/css2?family=Anton&family=Arimo:wght@400;600;700&display=swap",
  },
  {
    name: "Arvo",
    url: "https://fonts.googleapis.com/css2?family=Arvo:wght@400;700&display=swap",
  },
  {
    name: "Catamaran",
    url: "https://fonts.googleapis.com/css2?family=Catamaran:wght@400;600;700;800&display=swap",
  },
  // { name: "Comic Sans", url: "" }, // Not available on https://fonts.google.com/
  {
    name: "Comic Neue",
    url: "https://fonts.googleapis.com/css2?family=Comic+Neue:wght@400;600;700;800&display=swap",
  },
  { name: "Courier New", url: "" }, // Not available on https://fonts.google.com/, but considered web safe
  {
    name: "Della Respira",
    url: "https://fonts.googleapis.com/css2?family=Della+Respira&display=swap",
  },
  {
    name: "DM Sans",
    url: "https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;600;700;800&display=swap",
  },
  { name: "Georgia", url: "" }, // Not available on https://fonts.google.com/, but considered web safe
  {
    name: "Gilda Display",
    url: "https://fonts.googleapis.com/css2?family=Gilda+Display&display=swap",
  },
  // { name: "Helvetica", url: "" }, // Not available on https://fonts.google.com/
  {
    name: "Lato",
    url: "https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap",
  },
  {
    name: "Lora",
    url: "https://fonts.googleapis.com/css2?family=Lora:wght@400;600;700;800&display=swap",
  },
  // { name: "Lucida", url: "" }, // Not available on https://fonts.google.com/
  {
    name: "Marcellus",
    url: "https://fonts.googleapis.com/css2?family=Marcellus&display=swap",
  },
  {
    name: "Merriweather",
    url: "https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&display=swap",
  },
  {
    name: "Merriweather Sans",
    url: "https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@400;600;700;800&display=swap",
  },
  {
    name: "Nanum Gothic Coding",
    url: "https://fonts.googleapis.com/css2?family=Nanum+Gothic+Coding:wght@400;700&display=swap",
  },
  {
    name: "Neuton",
    url: "https://fonts.googleapis.com/css2?family=Neuton:wght@400;700;800&display=swap",
  },
  {
    name: "Noticia Text",
    url: "https://fonts.googleapis.com/css2?family=Noticia+Text:wght@400;700&display=swap",
  },
  {
    name: "Noto Sans",
    url: "https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;600;700;800&display=swap",
  },
  {
    name: "Open Sans",
    url: "https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&display=swap",
  },
  {
    name: "Playfair Display",
    url: "https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;600;700;800&display=swap",
  },
  {
    name: "Raleway",
    url: "https://fonts.googleapis.com/css2?family=Raleway:wght@400;600;700;800&display=swap",
  },
  {
    name: "Recursive",
    url: "https://fonts.googleapis.com/css2?family=Recursive:wght@400;600;700;800&display=swap",
  },
  {
    name: "Roboto",
    url: "https://fonts.googleapis.com/css2?family=Roboto:wght@400;600;700;800&display=swap",
  },
  // { name: "Source Sans Pro", url: "" }, // Not available on https://fonts.google.com/
  {
    name: "Source Sans 3",
    url: "https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@400;600;700;800&display=swap",
  },
  {
    name: "Source Code Pro",
    url: "https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400;600;700;800&display=swap",
  },
  {
    name: "Space Mono",
    url: "https://fonts.googleapis.com/css2?family=Space+Mono:wght@400;700&display=swap",
  },
  // Hidden as significantly wider than other fonts
  // {
  //   name: "Syncopate",
  //   url: "https://fonts.googleapis.com/css2?family=Syncopate:wght@400;700&display=swap",
  // },
  { name: "Tahoma", url: "" }, // Not available on https://fonts.google.com/, but considered web safe
  { name: "Times New Roman", url: "" }, // Not available on https://fonts.google.com/, but considered web safe
  { name: "Trebuchet MS", url: "" }, // Not available on https://fonts.google.com/, but considered web safe
  { name: "Verdana", url: "" }, // Not available on https://fonts.google.com/, but considered web safe
  {
    name: "Work Sans",
    url: "https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;600;700;800&display=swap",
  },
];

const getRgbaFromColor = (color: string): Uint8ClampedArray => {
  const canvasContext = document.createElement("canvas").getContext("2d");
  if (!canvasContext) return new Uint8ClampedArray([0, 0, 0, 0]);

  canvasContext.fillStyle = color;
  canvasContext.fillRect(0, 0, 1, 1);

  return canvasContext.getImageData(0, 0, 1, 1).data;
};

const getRgbLightness = (rgb: Uint8ClampedArray): number => {
  const [r, g, b] = Array.from(rgb);

  const min = Math.min(r, g, b);
  const max = Math.max(r, g, b);
  const avg = (min + max) / 2 / 255;

  return avg * 100;
};

const setRgbAlpha = (rgb: Uint8ClampedArray, alpha: number) => {
  const [r, g, b] = Array.from(rgb);
  const a = Math.round(Math.min(Math.max(alpha ?? 1, 0), 1) * 255);

  return new Uint8ClampedArray([r, g, b, a]);
};

const channelToHex = (channel: number) => {
  const hex = channel.toString(16);
  return hex.length === 1 ? `0${hex}` : hex;
};

const rgbaToHex = (rgba: Uint8ClampedArray) => {
  const [red, green, blue, alpha] = Array.from(rgba);

  const r = channelToHex(red);
  const g = channelToHex(green);
  const b = channelToHex(blue);
  const a = channelToHex(alpha);

  return "#" + r + g + b + a;
};

export const getColorsFromPrimary = (
  primary: string
): Pick<AccountTheme, "primary" | "primaryContrast" | "primaryHover"> => {
  const rgbaPrimary = getRgbaFromColor(primary);
  const rgbaPrimaryHover = setRgbAlpha(rgbaPrimary, 0.8);
  const lightness = getRgbLightness(rgbaPrimary);

  return {
    primary,
    primaryContrast: lightness > 60 ? "#000000" : "#FFFFFF",
    primaryHover: rgbaToHex(rgbaPrimaryHover),
  };
};
